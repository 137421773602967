<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
<h3>Tile view</h3>

<p>The “tile view” provides an alternative way to visualize the standards in a framework. You may find that it allows you to better understand relationships between “sibling” and “cousin” standards. You may also prefer to use this view when viewing standards on a smartphone, as works well on a small screen (as shown in the screenshot below).</p>
<p><img srcset="/docimages/kebab_menu-1.png 4x" class="k-help-img float-right">To enter tile view, click the <span v-html="link('kebab_menu', '“kebab” menu')"></span> in the upper-right corner of the window and click TILES on the Viewer Mode toggle button.<img srcset="/docimages/tileview-1.png 3x" class="k-help-img block"></p>
<img srcset="/docimages/tileview-2.png 3x" class="k-help-img float-right">
<ul class="k-no-clear">
	<li>Items marked by the <v-icon small class="mx-1">fas fa-circle-chevron-right</v-icon> icon have “child” items. Click the icon or the item text to reveal its children. Click the item again to hide its children.</li>
	<li>By default, when you click a parent item, the parent’s siblings will be hidden, to help you focus on this “strand” of items. To re-show the parent’s siblings, click the <v-icon small class="mx-1">fas fa-circle-plus</v-icon> icon that will appear to the right of the parent.</li>
	<li>Or, hold down the COMMAND (Mac)/ALT (Windows) key while you click a parent item to reveal the children of both the item you clicked <i>and all its siblings</i>.</li>
	<li>Similarly, if you hold down the COMMAND (Mac)/ALT (Windows) key while you click a parent item that is already open, this will hide the children of the parent and all its siblings.</li>
	<li>Hover over an item and click the <v-icon small class="mx-1">fas fa-expand</v-icon> icon to show the “expanded tile”, where you can view additional information about the item, including notes, supplemental information, comments, and associations.</li>
	<li>Click the <v-icon small>fas fa-tree</v-icon> button in the bottom-right corner of the window to return to the default <span v-html="link('treeview', '“tree view”')"></span>.</li>
</ul>

</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
}
</script>

<style lang="scss">
</style>
